import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ScreenOrientation } from '@capacitor/screen-orientation';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
constructor(private platform: Platform){
  
/* 
  Compruba que la aplicacion no se este ejecutando en web ya que la web no permite la orientación de pantalla
  y si no es asi ejecuta la funcion de bloqueo de orientacion en Vertical.
*/
  if (!this.platform.is('mobileweb') && !this.platform.is('desktop') ) {
    ScreenOrientation.lock({ orientation: 'portrait' });
  }
}



}

