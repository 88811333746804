export const api = {
  /* URL */

  url: 'https://control-presencia.quanticpro.com/api/',
  //url: 'http://localhost/api/',

  /* LOGIN */
  login: 'login',

  /* WORKER */
  worker: 'worker',
  companysWorker: 'companys-worker/',
  workerId: 'worker/',
  workerData: 'worker-Com/',

  /* CHECK */
  checkIn: 'check-in',
  checkOut: 'check-out',

  /* WORKER TIMES */
  workerTimes: 'worker-times/',

  /* COMPANYS WORKER */
  companyId: 'companyId/',
};
