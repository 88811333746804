import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { api } from 'src/environments/api';
import { User } from '../model/user';
import { Geolocation } from '@capacitor/geolocation';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private apiUrl: string = api.url
  private logIn: string = api.login
  private worker: string = api.worker
  private checkin: string = api.checkIn
  private checkout: string = api.checkOut
  private workerTimes: string = api.workerTimes
  private companyId = api.companyId
  private companysWorker: string = api.companysWorker

  private user: User;



  /* GEOLOCALIZACION Entrada */
  latitudeCheckIn: string;
  longitudeCheckIn: string;
  /* GEOLOCALIZACION Salida */
  latitudeCheckOut: string;
  longitudeCheckOut: string;
  /* OPTIONS LOCALIZACION */
  private options = {
    timeout: 2000,
  };

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.getToken()
    })
  }

  constructor(private http: HttpClient) {
  }
/*
 */

setUsuario(objeto: User) {
  this.user = objeto;
}

getUsuario() {
  return this.user;
}
  /**
 * Realiza la petición de inicio de sesión al servidor.
 * @param dni DNI del usuario.
 * @param pass Contraseña del usuario.
 * @returns Un observable con la respuesta del servidor.
 */
  login(dni: string, pass: string): Observable<any> {
    // Se crea un objeto FormData para enviar los datos de inicio de sesión.
    const fd = new FormData()
    // Se añade el DNI y la contraseña al objeto FormData.
    fd.append('dni', dni)
    fd.append('password', pass)
    // Se envía la petición POST al servidor con los datos del inicio de sesión.
    return this.http.post(this.apiUrl + this.logIn, fd)
  }

  /* Función para validar el token de autenticación del usuario en el backend
   * Retorna un observable que emite un booleano indicando si el token es válido o no
   */
  validarToken(): Observable<boolean> {
    // Se configuran las opciones para la solicitud HTTP con el token del usuario
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getToken() // Se agrega el token de autenticación en los headers de la solicitud
      })
    }
    // Se realiza una solicitud GET al backend para validar el token del usuario
    return this.http.get(this.apiUrl + this.worker, this.httpOptions).pipe(
      // Si el servidor responde con éxito, se retorna un valor booleano 'true'
      map(resp => {
        return true
      }),
      // Si el servidor responde con error, se captura el error y se retorna un valor booleano 'false'
      catchError(err => of(false))
    )
  }

  /*
   * Función que realiza una petición GET para obtener la información del usuario actual autenticado.
   * Devuelve un Observable que contiene la respuesta de la petición en formato JSON.
   *
   * El objeto httpOptions se utiliza para establecer las opciones de la petición HTTP,
   * en este caso se establece el tipo de contenido 'Accept' como 'application/json' y se incluye
   * el token de autenticación en la cabecera 'Authorization'.
   *
   * El método get de HttpClient realiza la petición HTTP GET y devuelve un Observable
   * que emite la respuesta HTTP como un objeto JSON.
   */
  getUser(): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      })
    }
    return this.http.get(this.apiUrl + this.worker, this.httpOptions)
  }

  /*

  OBTENER TODAS LAS COMPANIAS A LAS QUE PERTENECE UN TRABAJADOR

  */

  /*  */

  /*
    * Método que realiza el check-in del usuario en la aplicación
    * Recibe como parámetro un objeto de tipo User con los datos del usuario
    */
  checkIn(user: User): Observable<any> {

    // Se configuran las opciones para la petición HTTP, incluyendo el token de autorización
    this.httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      })
    }
    // Se obtiene la latitud y longitud del check-in
    this.LaTCheckIn();
    this.LonCheckIn();
    // Se crea un objeto FormData para enviar los datos del usuario y la ubicación del check-in
    const fd = new FormData()
    fd.append('worker_id', String(user.worker_id))
    fd.append('company_id', String(user.company_id))
    fd.append('latitudCheckIn', String(this.latitudeCheckIn))
    fd.append('longitudCheckIn', String(this.longitudeCheckIn))
    // Se realiza una petición HTTP POST con los datos del usuario y la ubicación del check-in
    // Se retorna un objeto Observable con la respuesta de la petición
    return this.http.post(this.apiUrl + this.checkin, fd, this.httpOptions)
  }

  /*
  OBTENER LOS ID DE LAS COMPAÑIAS A LAS QUE PERTENECE UN TRABAJADOR x EL DNI DEL TRABAJADOR
  */

  //REVISARLO
  getCompanys(user: User): Observable<any> {

    this.httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      })
    }
  console.log(user.worker_dni)
    return this.http.get(this.apiUrl + this.companysWorker + user.worker_dni, this.httpOptions);
  }



  /*
    * Método que realiza el check-out del usuario en la aplicación
    * Recibe como parámetro un objeto de tipo User con los datos del usuario
    */
  checkOut(user: User) {
    // Se configuran las opciones para la petición HTTP, incluyendo el token de autorización

    this.httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      })
    }
    // Se obtiene la latitud y longitud del check-out
    this.LaTCheckOut()
    this.LonCheckOut()
    // Se crea un objeto FormData para enviar los datos del usuario y la ubicación del check-out
    const fd = new FormData()
    fd.append('worker_id', String(user.worker_id))
    fd.append('company_id', String(user.company_id))
    fd.append('latitudCheckOut', String(this.latitudeCheckOut))
    fd.append('longitudCheckOut', String(this.longitudeCheckOut))
    // Se realiza una petición HTTP POST con los datos del usuario y la ubicación del check-out
    // Se retorna un objeto Observable con la respuesta de la petición
    return this.http.post(this.apiUrl + this.checkout, fd, this.httpOptions)
  }





  // GEOLOCALITATION

  // GEOLOCALITATION Entrada

  /*
   * Esta función asincrónica utiliza la API de Geolocalización para obtener la latitud actual del usuario en el momento del
   * check-in.
   */
  async getLatitudCheckIn() {
    // Se espera a que se obtenga la posición actual del usuario.
    const position = await Geolocation.getCurrentPosition(this.options);
    // Se obtiene la latitud de la posición actual y se convierte en un string.
    let lCheckIn = position.coords.latitude + '';
    // Se devuelve la latitud como un string.
    return lCheckIn;
  }

  /*
   * Esta función asincrónica utiliza la API de Geolocalización para obtener la longitud actual del usuario en el momento
   * del check-in.
   */
  async getLongitudCheckIn() {
    // Se espera a que se obtenga la posición actual del usuario.
    const position = await Geolocation.getCurrentPosition(this.options);
    // Se obtiene la longitud de la posición actual y se convierte en un string.
    let longitudCheckIn = position.coords.longitude + '';
    // Se devuelve la longitud como un string.
    return longitudCheckIn;
  }

  // GEOLOCALITATION Salida

  /*
   * Esta función asincrónica utiliza la API de Geolocalización para obtener la latitud actual del usuario en el momento del
   * check-out.
   */
  async getLatitudCheckOut() {
    // Se espera a que se obtenga la posición actual del usuario.
    const position = await Geolocation.getCurrentPosition(this.options);
    // Se obtiene la latitud de la posición actual y se convierte en un string.
    let latitudeCheckOut = position.coords.latitude + '';
    // Se devuelve la latitud como un string.
    return latitudeCheckOut;
  }
  /*
     * Esta función asincrónica utiliza la API de Geolocalización para obtener la longitud actual del usuario en el momento
     * del check-out.
     */
  async getLongitudCheckOut() {
    // Se espera a que se obtenga la posición actual del usuario.
    const position = await Geolocation.getCurrentPosition(this.options);
    // Se obtiene la longitud de la posición actual y se convierte en un string.
    let longitudCheckOut = position.coords.longitude + '';
    // Se devuelve la longitud como un string.
    return longitudCheckOut;
  }

  /* FUNCIONES PARA OBTENER LAS PROMESAS */

  /*
    * Este método se encarga de llamar a la función "getLatitudCheckIn" que retorna
    * una promesa con la posición actual del usuario.
    * Luego, se crea una promesa y se le asigna el resultado de "getLatitudCheckIn".
    * Cuando la promesa se resuelve, se actualiza el valor de "latitudeCheckIn" con el valor de la promesa.
   */
  LaTCheckIn() {
    const promise = Promise.resolve(this.getLatitudCheckIn());
    promise.then((value) => {
      this.latitudeCheckIn = value;
    });

  }

  /*
      * Este método se encarga de llamar a la función "getLongitudCheckIn" que retorna
      * una promesa con la posición actual del usuario.
      * Luego, se crea una promesa y se le asigna el resultado de "getLongitudCheckIn".
      * Cuando la promesa se resuelve, se actualiza el valor de "longitudeCheckIn" con el valor de la promesa.
     */
  LonCheckIn() {
    const promise = Promise.resolve(this.getLongitudCheckIn());
    promise.then((value) => {
      this.longitudeCheckIn = value;
    });

  }
  /*
      * Este método se encarga de llamar a la función "getLatitudCheckOut" que retorna
      * una promesa con la posición actual del usuario.
      * Luego, se crea una promesa y se le asigna el resultado de "getLatitudCheckOut".
      * Cuando la promesa se resuelve, se actualiza el valor de "latitudeCheckOut" con el valor de la promesa.
     */
  LaTCheckOut() {
    const promise = Promise.resolve(this.getLatitudCheckOut());
    promise.then((value) => {
      this.latitudeCheckOut = value;
    });
  }
  /*
        * Este método se encarga de llamar a la función "getLongitudCheckOut" que retorna
        * una promesa con la posición actual del usuario.
        * Luego, se crea una promesa y se le asigna el resultado de "getLongitudCheckOut".
        * Cuando la promesa se resuelve, se actualiza el valor de "longitudeCheckOut" con el valor de la promesa.
       */
  LonCheckOut() {
    const promise = Promise.resolve(this.getLongitudCheckOut());
    promise.then((value) => {
      this.longitudeCheckOut = value;
    });
  }

  /*
   * Esta función obtiene el tiempo trabajado por un usuario y devuelve un observable que contiene la respuesta.
   * También establece los encabezados de la solicitud http con token de autorización y tipo de aceptación.
   * Llama a las funciones para obtener la latitud y longitud para el check-in y check-out.
   * @param usuario Un objeto que contiene la información del usuario.
   * @returns Un observable que contiene la respuesta de la solicitud http.
  */
  workerTime(user: User): Observable<any> {
    // Establecer las opciones de la petición HTTP con el token de autenticación
    this.httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      })
    }

    //Obtiene una pregarga de las coordenadas del ususario para que asi la cuando las obtenga al hacer check-in y check-out
    //se realize de manera mas rapida.
    this.LaTCheckIn();
    this.LonCheckIn();
    this.LaTCheckOut()
    this.LonCheckOut()
    // Realizar la petición HTTP GET al endpoint de los tiempos del trabajador
    return this.http.get(this.apiUrl + this.workerTimes + user.worker_id, this.httpOptions)
  }

  /*
  *Función que permite establecer el token de autenticación en el almacenamiento local
  * para su posterior uso en las solicitudes HTTP
   */
  setToken(token: string) {
    localStorage.setItem("jwt", token)
  }
  /*
   *Función que permite obtener  el token de autenticación del almacenamiento local para su posterior uso.
    */
  getToken(): string {
    return String(localStorage.getItem("jwt"))
  }
  /*
   * Funcion para eliminar el toquen una vez se realize el logout
  */
  logout() {
    localStorage.removeItem("jwt")
  }
}
