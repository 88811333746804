import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'history',
    loadChildren: () => import('./pages/history/history.module').then( m => m.HistoryPageModule),
    canActivate: [AuthGuard]
  },
  
  {
    path: 'requests',
    loadChildren: () => import('./pages/requests/requests.module').then( m => m.RequestsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-requests',
    loadChildren: () => import('./pages/new-requests/new-requests.module').then( m => m.NewRequestsPageModule)
    ,canActivate: [AuthGuard]
  },
  {
    path: 'select-date',
    loadChildren: () => import('./modals/select-date/select-date.module').then( m => m.SelectDatePageModule)
    ,canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
    ,canActivate: [AuthGuard]
  },
  {
    path: 'select-company',
    loadChildren: () => import('./pages/select-company/select-company.module').then( m => m.SelectCompanyPageModule)
  },
  {
    path: '**',
    redirectTo: 'login',
  },
  
 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
